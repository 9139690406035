import React, { useState } from 'react';
import './CamerasView.css';
import { Row, Col, Container, Image, Button, Tabs, Tab, Form } from 'react-bootstrap';

import { postProcessScript } from '../../utils/structureScript';
import LoadingInformation from '../Modals/LoadingInformation';
import EditCameraShot from './EditCameraShot';
import EditCameraDirection from './EditCameraDirection';
import DeleteCameraShot from './DeleteCameraShot';
import { div } from 'three/nodes';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';

const CamerasView = ({ data, updateData, setError }) => {
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [onlyDisplayCameraDirection, setOnlyDisplayCameraDirection] = useState(true);
    const [editPaneShow, setEditPaneShow] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [editInstance, setEditInstance] = useState(null);
    const [deletePaneShow, setDeletePaneShow] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [activeScene, setActiveScene] = useState(0);

    function getImageForShotType(instance) {
        if (instance.type !== 'CameraDirection') {
            return '/camerashots/placeholder.webp';
        }

        switch (instance.shot_type) {
            case 'Wide shot':
                return '/camerashots/wideshot.JPG';
            case 'Full shot':
                return '/camerashots/full.JPG';
            case 'Medium shot':
                return '/camerashots/medium.JPG';
            case 'Medium close-up':
                return '/camerashots/medium close.JPG';
            case 'Establishing shot':
                return '/camerashots/establishing.webp';
            case 'High angle':
                return '/camerashots/highangle.JPG';
            case 'Low angle':
                return '/camerashots/lowangle.JPG';
            case 'Two shot':
                return '/camerashots/twoshot.JPG';
            case 'Three shot':
                return '/camerashots/threeshot.JPG';
            case 'Close-up':
                return '/camerashots/closeup.JPG';
            default:
                return '/camerashots/placeholder.webp';
        }
    }

    const parseCameraShots = async () => {
        setLoading(true);
        setLoadingText('Parsing Script');

        const placeholderEmptyArrays = Array.from({ length: data.script.scenes.length }, () => []);
        updateData(placeholderEmptyArrays);

        try {
            const promises = data.script.scenes.map(async (scene, index) => {
                const { scriptJsonWithCameraShots } = await postProcessScript(data, setError, index);

                const newdata = data.cameras;
                newdata[index] = scriptJsonWithCameraShots.scenes;
                updateData(newdata);
            });

            await Promise.all(promises);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const parseScene = async scene => {
        setLoading(true);
        setLoadingText(`Parsing Scene ${parseInt(scene) + 1}`);

        try {
            const { scriptJsonWithCameraShots } = await postProcessScript(data, setError, scene);

            const newdata = data.cameras;
            newdata[scene] = scriptJsonWithCameraShots.scenes;
            console.log(newdata);
            updateData(newdata);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const deleteCameraEntry = entryIndex => {
        let updatedScene = data.cameras;
        updatedScene[activeScene].splice(entryIndex, 1);
        const updatedCameras = data.cameras.map((scene, i) => (i === activeScene ? updatedScene : scene));
        updateData(updatedCameras);
    };

    const handleCheckboxToggle = () => {
        setOnlyDisplayCameraDirection(prevState => !prevState);
    };

    const editCameraInstance = (index, newData) => {
        const updatedScene = data.cameras[activeScene].map((camera, i) => (i === index ? { ...camera, ...newData } : camera));
        const updatedCameras = data.cameras.map((scene, i) => (i === activeScene ? updatedScene : scene));
        updateData(updatedCameras);
    };

    const addCameraInstance = (index, newData) => {
        const updatedScene = data.cameras[activeScene];
        updatedScene.push(newData);
        const updatedCameras = data.cameras.map((scene, i) => (i === activeScene ? updatedScene : scene));
        updateData(updatedCameras);
    };

    function findNextCameraDirectionInstance(start, searchList, searchUpwards) {
        let i = searchUpwards ? start + 1 : start - 1;

        while (i > 0 && i < searchList.length) {
            if (searchList[i].type === 'CameraDirection' || searchList[i].type === 'Dialog') {
                return i;
            }

            searchUpwards ? i++ : i--;
        }

        return null;
    }

    const handleUpReorder = index => {
        const updatedCameras = data.cameras;
        const currentActiveList = updatedCameras[activeScene];
        if (index > currentActiveList.length || index === 0) {
            return;
        }

        const swapIndex = onlyDisplayCameraDirection ? findNextCameraDirectionInstance(index, currentActiveList, false) : index - 1;
        if (swapIndex === null) return;

        [currentActiveList[index], currentActiveList[swapIndex]] = [currentActiveList[swapIndex], currentActiveList[index]];
        updatedCameras[activeScene] = currentActiveList;
        updateData(updatedCameras);
    };

    const handleDownReorder = index => {
        const updatedCameras = data.cameras;
        const currentActiveList = updatedCameras[activeScene];
        if (index > currentActiveList.length - 1) {
            return;
        }

        const swapIndex = onlyDisplayCameraDirection ? findNextCameraDirectionInstance(index, currentActiveList, true) : index + 1;
        if (swapIndex === null) return;

        [currentActiveList[index], currentActiveList[swapIndex]] = [currentActiveList[swapIndex], currentActiveList[index]];
        updatedCameras[activeScene] = currentActiveList;
        updateData(updatedCameras);
    };

    const ShotTypeBox = ({ instance, index }) => {
        const renderInfo = (label, value) =>
            value ? (
                <p>
                    <strong>{label}:</strong> {value}
                </p>
            ) : null;

        return (
            <div className="camera-card rounded-2">
                {(!onlyDisplayCameraDirection || instance.type === 'CameraDirection') && (
                    <div className="camera-preview-image rounded-2">
                        <Image className="inner-image" src={getImageForShotType(instance)} />
                    </div>
                )}
                <div className="camera-card-content">
                    <div className="camera-card-header mb-1">
                        <h3 className="fs-3">{onlyDisplayCameraDirection ? (instance.shot_type ? instance.shot_type : instance.character) : instance.type}</h3>
                        <div>
                            <AiFillCaretDown className="autohide" onClick={() => handleDownReorder(index)} />
                            <AiFillCaretUp className="autohide" onClick={() => handleUpReorder(index)} />
                            {instance.type === 'CameraDirection' && (
                                <Button
                                    variant="outline-danger"
                                    className="autohide"
                                    onClick={() => {
                                        setDeletePaneShow(true);
                                        setEditIndex(index);
                                        setEditInstance(instance);
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                            {instance.type === 'CameraDirection' && (
                                <Button
                                    variant="outline-secondary"
                                    className="autohide"
                                    onClick={() => {
                                        setEditPaneShow(true);
                                        setEditIndex(index);
                                        setEditInstance(instance);
                                    }}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="camera-card-body">
                        {(instance.type !== 'CameraDirection' || !onlyDisplayCameraDirection) && renderInfo('Shot Type', instance.shot_type)}
                        {!onlyDisplayCameraDirection && renderInfo('Character', instance.character)}
                        {renderInfo('Movement', instance.movement_type)}
                        {renderInfo('Description', instance.description)}
                        {renderInfo('Line', instance.line)}
                        {renderInfo('Subject', instance.subject)}
                        {renderInfo('Movement Style', instance.movement_style)}
                        {renderInfo('End Location', instance.end_location)}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="App-content">
                <Container>
                    {data.cameras.length === 0 && (
                        <Button variant="primary" onClick={parseCameraShots}>
                            Parse Script
                        </Button>
                    )}
                    {data.cameras.length > 0 && (
                        <Row className="justify-content-md-center">
                            <Col xs={10} md={10} className="border p-4 shadow rounded position-relative">
                                <Tabs activeKey={activeScene} onSelect={k => setActiveScene(k)}>
                                    {data.cameras?.map((scene, index) => (
                                        <Tab eventKey={index} key={index} title={<>Scene {index + 1} </>}></Tab>
                                    ))}
                                </Tabs>

                                <Container>
                                    <Row className="mt-3">
                                        <Col className="justify-content-start">
                                            <Button
                                                variant="primary"
                                                className="m-2"
                                                onClick={() => {
                                                    setEditPaneShow(true);
                                                    setEditIndex(data.cameras.length + 1);
                                                    setEditInstance({
                                                        type: 'CameraDirection',
                                                        shot_type: '',
                                                        movement_type: '',
                                                        subject: '',
                                                    });
                                                    setAddMode(true);
                                                }}
                                            >
                                                Add Camera Direction
                                            </Button>
                                            <Button variant="primary" onClick={e => parseScene(activeScene)}>
                                                Re-Parse This Scene
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/*Code to select filtering on only CameraDirections. Uncomment to see the rest of DSS objects*/}
                                    {/*<Row className="mt-3">*/}
                                    {/*    <Col>*/}
                                    {/*        {data.cameras.length > 0 ? (*/}
                                    {/*            <Form.Check type="checkbox" label="Show Only Camera Directions" checked={onlyDisplayCameraDirection} onChange={handleCheckboxToggle} />*/}
                                    {/*        ) : (*/}
                                    {/*            <Form.Check type="checkbox" label="Show Only Camera Directions" checked={onlyDisplayCameraDirection} onChange={handleCheckboxToggle} disabled />*/}
                                    {/*        )}*/}
                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                </Container>
                                <Container fluid className="p-0">
                                    {data.cameras[activeScene]?.map(
                                        (instance, index) =>
                                            (!onlyDisplayCameraDirection || instance.type === 'CameraDirection' || instance.type === 'Dialog') && (
                                                <div key={index} className="mt-3 border p-3 shadow-sm rounded position-relative">
                                                    <ShotTypeBox index={index} instance={instance} />
                                                </div>
                                            )
                                    )}
                                </Container>
                                {loading && <LoadingInformation text={loadingText} />}
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            <EditCameraDirection
                show={editPaneShow}
                index={editIndex}
                instance={editInstance}
                onSubmit={addMode ? addCameraInstance : editCameraInstance}
                onHide={() => {
                    setAddMode(false);
                    setEditPaneShow(false);
                    setEditIndex(null);
                    setEditInstance(null);
                }}
            />
            <DeleteCameraShot
                show={deletePaneShow}
                index={editIndex}
                instance={editInstance}
                deleteCameraEntry={deleteCameraEntry}
                onHide={() => {
                    setDeletePaneShow(false);
                    setEditPaneShow(null);
                    setEditInstance(null);
                }}
            />
        </>
    );
};

export default CamerasView;
