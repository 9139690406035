const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL;

/**
 * Call the API to parse the script for camera movements and general shot directions. Parses all scenes together
 * when <sceneNumber> is not provided. Parses specified scene when sceneNumber is provided.
 *
 * @param data JavaScript object containing the project
 * @param setError Function to display an error modal to user
 * @param sceneNumber
 * @returns {Promise<{scriptText: *, scriptJsonWithCameraShots: any}|*[]>}
 */
export const postProcessScript = async (data, setError, sceneNumber = -1) => {
    const url = `${MAIN_API_URL}/post_process_script`;

    // Check if the requested sceneNumber exists
    if (sceneNumber !== -1 && !data.script.scenes[sceneNumber]) {
        setError(`Failed to parse scene ${sceneNumber}`);
        setTimeout(() => {
            setError('');
        }, 10000);
        return [];
    }
    const scenes_text = sceneNumber === -1 ? data.script.scenes.map(scene => scene.scene_text) : [data.script.scenes[sceneNumber].scene_text];

    try {
        // API call to process script scenes
        const scenes_text = data.script.scenes.map(scene => scene.scene_text);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(scenes_text),
        });

        if (!response.ok) {
            // Handle network errors or non-200 responses here
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const processedScript = await response.json();
        const scriptJsonWithCameraShots = JSON.parse(processedScript.script_json);
        const scriptText = processedScript.script_text;

        if (sceneNumber === -1) {
            return { scriptJsonWithCameraShots, scriptText }; // Return as an object
        } else {
            return { scriptJsonWithCameraShots, scriptText };
        }
    } catch (error) {
        setError('Failed to create package. Please try again.');
        setTimeout(() => {
            setError('');
        }, 10000);
        return []; // Return an empty array in case of an error
    }
};
